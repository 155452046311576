<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <base-text-field
        ref="name"
        v-model="name"
        label="Name"
      />

      <base-text-field
        ref="email"
        v-model="email"
        :rules="emailRules"
        label="Email"
        required
      />

      <base-textarea
        ref="message"
        v-model="message"
        :rules="messageRules"
        class="mb-6"
        label="Your Need & Description"
        required
      />

      <base-btn
        :color="!theme.isDark ? 'accent' : 'white'"
        outlined
        :disabled="!valid"
        @click="submit"
      >
        Send message
      </base-btn>
    </v-form>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'BaseContactForm',

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },

    data: () => ({
      valid: true,
      name: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      messageRules: [
        v => !!v || 'Please enter a message',
      ],
      message: '',
    }),

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    methods: {
      submit () {
        if (this.$refs.form.validate()) {
          axios.post('/bin/contact.cgi', {
            name: this.name,
            email: this.email,
            message: this.message,
          }).then(() => {
            this.clear()
            this.$refs.message.success = true
            this.$refs.message.successMessages = 'Thanks!  I got it.'
          }).catch(() => {
            // console.log(error);
          })
        }
      },
      clear () {
        this.$refs.message.success = false
        this.$refs.message.successMessages = ''
        this.$refs.form.reset()
      },
    },
  }
</script>
